import React from 'react'
import WfccesForm from './components/Form'

function Wfcces() {
  return (
    <div className=''>
      <WfccesForm/>
    </div>
  )
}

export default Wfcces
